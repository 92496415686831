import { ReactNode } from "react";
import { Box } from "@mui/material";

import { TableColumn } from "../../DataTable/types";
import { MhcLocationStatFragment, MhcStatIdentifierFragment } from "graphqlApi/types";

import { noDataMessage } from "common/content/messages";
import { showNeedsAttentionIcon } from "common/components/Icons/utils";
import { getValueOptionsFromSi } from "common/util/formatHelpers/statIdentifierHelpers";

import { DynamicTrendLineChart } from "common/components/charts/DynamicTrendLineChart";
import { ChangeLabel } from "../../ChangeLabel";
import { SuppressionTooltipNA } from "../../SuppressionTooltip/SuppressionTooltip";
import { IndicatorTableRow } from "../IndicatorTable";
import { LocationValueWrapper } from "./LocationValueWrapper";
import { COLUMN_WIDTHS } from ".";

export const columnContentMaxWidth = (grow?: number, isMobile = false) =>
  isMobile ? undefined : `${200 + (grow ?? 0)}px`;

interface BasicTableCellArgs {
  id: string;
  width?: string;
  isMobile?: boolean;
  align?: "left" | "center" | "right";
}

type CellWithTrendline = BasicTableCellArgs &
  Pick<
    IndicatorTableRow,
    | "benchmark"
    | "benchmarkImprovementType"
    | "granularity"
    | "locationStat"
    | "locationValue"
    | "percentageChange"
    | "range"
    | "si"
    | "target"
    | "timeSeries"
  >;

interface TableCellArgs<P> extends BasicTableCellArgs {
  display?: ReactNode;
  useRatioForValues?: boolean;
  sortable?: boolean;
  render?: (row: P) => ReactNode;
}

type BaseTableRow = {
  id: string;
};

export const genericTableCell = <P extends BaseTableRow>({
  id,
  display,
  align = "center",
  sortable = false,
  width,
  render
}: TableCellArgs<P>): TableColumn<P> => ({
  id,
  sortable,
  width,
  display,
  align,
  render
});

export const primaryStatTableCell = <
  P extends BaseTableRow & { si: MhcStatIdentifierFragment; locationStat?: MhcLocationStatFragment }
>({
  id = "location",
  display,
  width = COLUMN_WIDTHS.primary,
  useRatioForValues
}: TableCellArgs<P>): TableColumn<P> => ({
  id,
  sortable: true,
  width,
  display,
  align: "center",
  render: (row) => <LocationValueWrapper useRatioForValues={useRatioForValues} {...row} />
});

export const trendLineTableCell = <P extends CellWithTrendline>({
  id = "trendline",
  width = COLUMN_WIDTHS.secondary,
  trendWidth = 160
}: TableCellArgs<P> & { trendWidth?: number }): TableColumn<P> => ({
  id,
  display: "Trendline",
  align: "center",
  width,
  render: (row) => {
    const { timeSeries, si, locationStat } = row;
    if (timeSeries !== undefined) {
      if (timeSeries?.dates && timeSeries?.dates.length > 1) {
        return (
          <Box
            sx={{
              m: -1.75,
              display: "flex",
              justifyContent: "center"
            }}
          >
            <DynamicTrendLineChart
              height={75}
              width={trendWidth}
              valueOptions={getValueOptionsFromSi(si)}
              timeSeries={timeSeries}
              benchmark={row.benchmark}
              granularity={row.granularity ?? locationStat?.granularity}
              title={si.name}
              subtitle={si?.subtitle || undefined}
              statCaption={si?.statCaption ?? undefined}
            />
          </Box>
        );
      }
      return <SuppressionTooltipNA overrideMessage={noDataMessage("trendline")} />;
    }
    return (
      <SuppressionTooltipNA overrideMessage="No trendline is shown for this indicator because data is not available." />
    );
  }
});

export const percentChangeTableCell = <P extends CellWithTrendline>({
  id = "percent_change",
  width = COLUMN_WIDTHS.tertiary,
  maxWidth
}: BasicTableCellArgs & { maxWidth?: number | string }): TableCellArgs<P> => ({
  id,
  display: "% Change",
  align: "center",
  width,
  render: (row) => {
    if ((row?.timeSeries?.dates?.length ?? 0) <= 1) {
      return <SuppressionTooltipNA overrideMessage={noDataMessage("% change")} />;
    }
    const { locationValue, target } = row;

    const needsAttention = showNeedsAttentionIcon({
      currentValue: locationValue,
      target: target?.value,
      improvementType: row.benchmarkImprovementType
    });

    if (row.range !== undefined && row.benchmark !== undefined) {
      return (
        <ChangeLabel
          percentageChange={row.percentageChange}
          improvementType={row.benchmarkImprovementType}
          maxWidth={maxWidth}
          needsAttention={needsAttention}
        />
      );
    }
    return (
      <SuppressionTooltipNA overrideMessage="No % Change is shown for this indicator because data is not available." />
    );
  }
});
